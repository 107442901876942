@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;600;700&display=swap');


html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: 'Source Sans 3', sans-serif;
}

.pl80 {
  padding-left: 80px;
}

.pt80 {
  padding-top: 80px;
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background-color: #e0e0e0;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
}

::-webkit-scrollbar-track:hover {
  background-color: #555;
}

.react-datepicker__input-container input {
  width: 100%;
  height: 50px;
  border-width: 0;
  border-bottom-color: #8b8b8b;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-bottom-width: 1px;
  background-color: #f0f0f0;
  color: #525252;
  padding-left: 10px;
  font-size: 12px;
}